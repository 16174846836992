<template>
  <div>
    <div ref="canvas"></div>
  </div>
</template>

<script>
export default {
  name: 'homeIndex',
  mounted () {
    const canvas = this.$refs.canvas
    // eslint-disable-next-line no-undef
    lottie.loadAnimation({
      container: canvas, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/theo-boxing.json' // the path to the animation json
    })
  }
}
</script>

<style scoped>

</style>
