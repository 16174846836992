import Vue from 'vue'
import VueRouter from 'vue-router'
// import AboutView from '../views/AboutView.vue'
import HomeIndex from '../views/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeIndex
  }
]

const router = new VueRouter({
  routes
})

export default router
